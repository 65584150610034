@use "libs/styles/src/lib/variables";
@use "sass:color";
@use "rfs/scss" as rfs;

@mixin pill-color($color) {
  color: $color;

  &.outline:hover {
    border-color: $color;
    background-color: rgba($color, 0.1);
  }

  &:not(.outline) {
    background-color: rgba($color, 0.15);
  }

  &.fill {
    color: white;
    background: $color;
  }
}

.pill {
  @include variables.header(3);
  @include rfs.rfs(8px, gap);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  padding: 0.5rem 1.5rem;
  background-color: variables.$color-off-light;
  transition: variables.$transition-s;

  & > * {
    color: variables.$color-disabled;
  }

  &.selectable {
    cursor: pointer;

    &:hover {
      background-color: color.adjust(variables.$color-org, $lightness: 15%);
    }
  }

  &.size-tiny {
    @include rfs.padding(4px 12px);
    @include variables.label;
    border-radius: 16px;
    font-weight: 500;
  }

  &.size-large {
    @include rfs.padding(12px 28px);
    border-radius: 16px;

    @media (max-width: variables.$device-md) {
      @include rfs.padding(12px 24px);
    }
  }

  &.color-orange {
    @include pill-color(variables.$color-fund);
  }

  &.color-purple {
    @include pill-color(variables.$color-purple);
  }

  &.color-blue {
    @include pill-color(variables.$color-org);
  }

  &.outline {
    border: 1px solid variables.$color-lighter;
    color: inherit;
    background: none;
    transition: variables.$transition-s;
  }
}
