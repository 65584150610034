@use "libs/styles/src/lib/variables";
@use "rfs/scss" as rfs;

.section-label {
  @include rfs.padding-bottom(12px);
  @include rfs.margin-bottom(32px);
  @include variables.header(1);
  width: fit-content;
  border-bottom: 1px solid variables.$color-purple;
  text-align: center;
  color: variables.$color-dark-light;

  &.smaller {
    @include variables.header(2);
  }
}
