@use "libs/styles/src/lib/variables";
@use "rfs/scss" as rfs;

.social-container {
  @include rfs.margin-top(85px);
  @include rfs.margin-bottom(85px);
  @include rfs.rfs(35px, gap);
  @include variables.max-width;
  display: flex;
  flex-flow: wrap;
}

.social-card {
  @include rfs.padding-top(42px);
  @include rfs.padding-bottom(38px);
  @include variables.card-hover;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-evenly;
  align-items: center;
  color: variables.$color-slate;
  cursor: pointer;

  @media (max-width: variables.$device-md) {
    flex-basis: 40%;

    @include rfs.padding-bottom(22px);
  }

  &:hover {
    color: variables.$color-purple;

    span {
      color: variables.$color-purple;
    }
  }

  span {
    @include variables.header(4);
    @include rfs.margin-top(13px);
    color: variables.$color-disabled;
    transition: variables.$transition-m;
  }
}
