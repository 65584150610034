@use "libs/styles/src/lib/variables";
@use "~rfs/scss" as rfs;

.media-carousel {
  @include rfs.margin(40px 120px 80px);
  @include variables.max-width;
  align-self: center;

  & > div {
    @include rfs.padding(30px 60px 60px 90px);
    @include variables.side-fade-gradients;

    @media (max-width: variables.$device-sm) {
      @include rfs.padding(60px 32px 90px);
      @include variables.side-fade-gradients($width: 80px);
    }
  }
}

.media-card {
  @include rfs.padding-top(42px);
  @include variables.card-hover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 525px;
  height: 360px;
  overflow: hidden;

  p {
    @include rfs.margin-bottom(25px);
    @include variables.paragraph;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    color: variables.$color-gray;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.media-source {
  @include rfs.margin-bottom(10px);

  img {
    max-width: 100%;
    max-height: 50px;
    object-fit: contain;
    object-position: bottom;
  }

  &.source-text {
    @include variables.title(2);
    font-weight: 800;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 25%);
  }
}

.media-title {
  @include rfs.margin-bottom(16px);
  @include variables.header(2);
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  color: variables.$color-dark-light;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.media-date {
  @include variables.paragraph;
  @include rfs.padding-top(16px);
  margin-top: auto;
  border-top: 1px solid variables.$color-lighter;
  color: variables.$color-dark-light;
}
